@font-face {
  font-family: "PathwayExtreme-Regular";
  src: url("../../public/assets/fonts/PathwayExtreme-Regular.ttf");
}

@font-face {
  font-family: "PathwayExtreme-Medium";
  src: url("../../public/assets/fonts/PathwayExtreme-Medium.ttf");
}

@font-face {
  font-family: "PathwayExtreme-SemiBold";
  src: url("../../public/assets/fonts/PathwayExtreme-SemiBold.ttf");
}

@font-face {
  font-family: "PathwayExtreme-Bold";
  src: url("../../public/assets/fonts/PathwayExtreme-Bold.ttf");
}
